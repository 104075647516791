import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin: 5rem 0;
`;

export const Email = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 1rem;

  span {
  }

  @media (min-width: 576px) {
    span {
    }
  }
  @media (min-width: 992px) {
    span {
    }
  }

  
  input{
    width: 50vw; 
    height: 40px; 
    border-radius: 5px;  
    border-style: solid; 
    border-width: 1px; 
    border-color: #380057; 
    margin-top: 10px;  
    padding-left: 10px;
    margin-bottom: 10px; 
  }
  
  
  textarea{
    width: 50vw; 
    height: 100px; 
    border-radius: 5px;  
    border-style: solid; 
    border-width: 1px; 
    border-color: #380057; 
    margin-top: 10px;  
    padding-left: 10px;
    margin-bottom: 20px; 
    padding-top: 15px; 
  }
  
  
  label{
    display: block; 
    float: center; 	
  }
  
  
  button{
    height: 45px; 
    width: 50vw; 
    padding-left: 5px;
    padding-right: 5px; 	
    margin-bottom: 20px; 
    margin-top: 10px; 	
    text-transform: uppercase;
    background-color: #380057; 
    border-color: #380057; 
    border-style: solid; 
    border-radius: 10px;	
    cursor: pointer;
  }
  
  
  button p{
    color: #fff; 
  }
  
  
  span{
    color: #380057; 
  }
  
  
  .aviso{
    color: #0e0e0e;  
  }
  
  
  h1{
    text-align: center; 
    padding-bottom: 20px; 
    color: #ec576b;
  }
  
  
  h3{
    padding-bottom: 30px;
    color: #0e0e0e;   
  }
  
  
  p{
    margin-bottom: 15px;
    color: #0e0e0e; 
  }
  
  
  ::-webkit-input-placeholder {
   color: #a8a8a8;
  }
  
  
  ::-webkit-textarea-placeholder {
   color: #a8a8a8;
  }
  
  
  .formulario input:focus{
    outline:0;
    border: 1px solid #380057;
  }
  
  
  .formulario textarea:focus{
    outline:0;
    border: 1px solid #380057;
  }

  .button-container {
    text-align: center;
  }
  
  .custom-button {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: #380057;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #ffffff;
    width: 1.5rem;
    height: 1.5rem;
    animation: spin 2s linear infinite;
    margin-right: 10px;
    display: inline-block;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
`;
