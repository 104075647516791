import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
import ScrollAnimation from "react-animate-on-scroll";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">Sobre Nosotros</div>
        <div className="BigCard">
        <ScrollAnimation animateIn="fadeInLeft">
          <Image
            src="/logo_final.png"
            alt="DNAut"
          />
        </ScrollAnimation>
          <div className="AboutBio">
            <ScrollAnimation animateIn="fadeInLeft">
            Somos <strong>DNAut</strong>, un equipo de profesionales del área de la informática cuyo compromiso 
            es brindar soluciones tecnológicas con una enfoque altamente personalizado para abordar desafíos y 
            necesidades específicas. Nuestra misión radica en la creación y ejecución de estrategias a medida, 
            meticulosamente diseñadas para cada cliente, con el fin de garantizar la satisfacción y el éxito de sus proyectos.
            </ScrollAnimation>

            <br />
            
            <ScrollAnimation animateIn="fadeInLeft">
            En el contexto de un entorno en constante evolución caracterizado por avances significativos en el campo 
            de la inteligencia artificial y la creciente superación de barreras tecnológicas, nos encontramos ante la 
            necesidad de afrontar los retos que se derivan de este panorama dinámico. En este entorno, la capacidad de adaptación 
            y la disposición para abrazar las innovaciones impulsadas por la industria son esenciales. Es fundamental comprender 
            que en esta era de la tecnología, los límites de lo que podemos concebir y desarrollar están determinados únicamente 
            por nuestra imaginación. 
            </ScrollAnimation>

            <br />

            <ScrollAnimation animateIn="fadeInLeft">
            En consecuencia, nuestros activos fundamentales para abordar cada nuevo reto son nuestras habilidades de comunicación, 
            creatividad y la versatilidad para incorporar las últimas tecnologías. Al ejercer una profesión de naturaleza analítica, 
            consideramos que el enfoque, la atención y la relación cercana con nuestros clientes son elementos cruciales que nos permiten 
            proporcionar herramientas y soluciones perdurables. Estos pilares constituyen la base de nuestro enfoque, orientado hacia la 
            entrega de resultados sólidos y sostenibles en un entorno empresarial en constante evolución.
            
            <br /><br />
            
            Te invitamos a conocer las tecnologías que hemos investigado e incorporado en el diseño y desarrollo de 
            nuestras soluciones:
            <br /><br /><br />
            </ScrollAnimation>
            

            <Technologies>
              {stackList.map((stack, index) => (
                <ScrollAnimation animateIn="fadeInLeft" key={index}>
                  <Tech key={index} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <TechName>{stack.name}</TechName>
                  </Tech>
                </ScrollAnimation>
              ))}
            </Technologies>
          </div>

        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
