import React, { useState, useRef } from 'react';
import { ContactWrapper, Email } from "./ContactElements";
import { MdContentCopy } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";
import Zoom from '@mui/material/Zoom';
import emailjs from '@emailjs/browser';

import ScrollAnimation from "react-animate-on-scroll";
function Contact() {

  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs.sendForm('service_cjcqt2e', 'template_5a3xjzl', form.current, 'j4C2MOosaC5v-N9Xm')
      .then((result) => {
        setTimeout(() => {
          // Termina la simulación después de 3 segundos
          setIsLoading(false);
        }, 3000);
      }, (error) => {
        setTimeout(() => {
          // Termina la simulación después de 3 segundos
          setIsLoading(false);
        }, 3000);
      });
    // Simula un proceso asincrónico, como una solicitud HTTP
  };


  return (
    <ContactWrapper id="contact">

      <div className="Container">
        <div className="SectionTitle">Contáctanos</div>
        <ScrollAnimation animateIn="fadeIn" >
          <div className="BigCard">
            <Email>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', rowGap: '10px', flexWrap: 'wrap', justifyContent: 'center' }} >
              <div className="contact_form">

        <div className="formulario">			
              <form ref={form} onSubmit={handleSubmit}>				
								<p>
									<label htmlFor="nombre" className="colocar_nombre">Nombre completo
										<span className="obrigatorio">*</span>
									</label>
										<input className="form-control" type="text" name="nombre" id="nombre"  placeholder="Ingresa tu nombre completo" required />
								</p>
							
								<p>
									<label htmlFor="email" className="colocar_email">Email
										<span className="obrigatorio">*</span>
									</label>
										<input className="form-control" type="email" name="email" id="email" required placeholder="Ingresa tu email" />
								</p>
							
								<p>
									<label htmlFor="mensaje" className="colocar_mensaje">Mensaje
										<span className="obligatorio">*</span>
									</label>                     
                  <textarea name="mensaje" className="form-control" id="mensaje" required placeholder="Escribe tu mensaje..." />
                </p>	  								
							
								<div className="button-container">
                  <button
                    className="custom-button"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isLoading}
                    name="enviar-formulario"
                  >
                    {isLoading ? (
                      <div className="spinner"></div>
                    ) : (
                      'Enviar'
                    )}
                  </button>
                </div>


								<p className="aviso">
									<span className="obrigatorio"> * </span>Campos obligatorios.
								</p>					
						
					</form>
		</div>	
	</div>
            </div>
              
            </Email>
          </div>
        </ScrollAnimation>

      </div>
    </ContactWrapper>
  );
}

export default Contact;
