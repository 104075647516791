import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import Header from "../Header/Header";
import {
  HeroContainer,
  HeroWrapper,
  HeroLeft,
  HeroRight,
  Image,
  ScrollDown,
  ScrollLink,
} from "./HeroElements";
import { TypeAnimation } from 'react-type-animation';
import ScrollAnimation from "react-animate-on-scroll";

function Hero() {
  const [isOpen, setIsOpen] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <main>
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <HeroContainer>
        <HeroWrapper>
          <HeroLeft>
            <ScrollAnimation animateIn="fadeIn" >
              <TypeAnimation
                cursor={false}
                sequence={[
                  'Bienvenido, somos DNAut',
                  () => setShowSubtitle(true)
                ]}
                speed={{ type: "keyStrokeDelayInMs", value: 150 }}
                wrapper="h1"
                repeat={0}
              />
              {showSubtitle &&
                <TypeAnimation
                  cursor={true}
                  sequence={[
                    500,
                    'Proporcionamos soluciones informáticas',
                    1000,
                    'Contáctanos',
                    // 'I design and code beautifully simple things, and I love what I do.',
                    1000,
                    'Explica tu problema o necesidad',
                    1000,
                    'Y trabajaremos para resolverlo',
                    1000,
                    '¿No sabes cómo?',
                    1000,
                    'Ve a la última sección',
                    1000,
                    "Pero antes revisa nuestro trabajo",
                    1000,
                    "Ahí podras obtener algunas ideas",
                    2000,
                    ":)",
                    1000,
                    "¿No estás seguro de que necesitas?",
                    300,
                    () => setShowScrollDown(true),
                    1000,
                    "Contáctanos!",
                    1000,
                    "Esperamos tu mensaje",
                    2000,
                    "Aún esperamos...",
                    2000,
                    "Bien, aquí vamos de nuevo...",
                    // 1000,
                    // "Or...",
                    // 1000,
                    // "Or... I could scroll you by force! Muahaha!",
                    //1000,
                    //"See ya! :)",
                    500,
                  ]}
                  speed={50}
                  deletionSpeed={65}
                  wrapper="h5"
                  repeat={Infinity}
                />
              }
            </ScrollAnimation>

          </HeroLeft>
          <HeroRight>
            <ScrollAnimation animateIn="fadeIn">
              {/* <Image
                src="/2iiH.gif"
                alt="man-svgrepo"
              /> */}
              <div class="spinner">
                  <div class="spinner1"></div>
              </div>
            </ScrollAnimation>
          </HeroRight>
        </HeroWrapper>
        {showScrollDown &&<ScrollAnimation animateIn="flipInX" offset={0}>
        <ScrollDown to="projects" id="scrollDown">
          <ScrollLink>
            Desplázate hacia abajo
            <img
              src="/scroll-down.svg"
              alt="scroll-down"
            />
          </ScrollLink>
        </ScrollDown>
        </ScrollAnimation>}
      </HeroContainer>
    </main>
  );
}

export default Hero;
