// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "/desarrollo.jpg",
    title: "Desarrollo de software y sistemas personalizados",
    description1:
    "En nuestra empresa dedicada a soluciones tecnológicas, estamos comprometidos en entender a fondo tus necesidades y metas comerciales. Con este conocimiento, creamos soluciones de software completamente personalizadas que se ajustan a tus requerimientos específicos.",
    description2: "Diseñamos la arquitectura de software que garantiza un alto rendimiento y escalabilidad óptima.",
    description3: "Desarrollamos sistemas completos, desde el apartado visual hasta la funcionalidad interna, asegurando una experiencia de usuario fluida y agradable.",
    tech_stack: ["Oracle", "Mysql", "MongoDB"],
    github_url: "",
    demo_url: "https://renr-bcit.web.app/",
  },
  {
    img: "/automatizacion.jpg",
    title: "Administración, gestión y automatización de procesos",
    description1:
    "Creamos soluciones que simplifican y agilizan tus procesos empresariales mejorando la eficiencia operativa. ",
    description2: "Organizamos y optimizamos tus archivos digitales para permitir un acceso rápido y eficiente.",
    description3: "Automatizamos tareas recurrentes para optimizar el uso de recursos valiosos como lo es el tiempo. Generamos informes y reportes para facilitar la toma decisiones basadas en análisis de datos recopilados en tiempo real.",
    tech_stack: ["HTML", "CSS", "NodeJs", "MongoDB", "SocketIo"],
    github_url: "https://github.com/salesp07/MyMind---Copy",
    demo_url: "https://mymindweb.vercel.app/",
  },
  {
    img: "/web.jpg",
    title: "Desarrollo web y comercio electrónico",
    description1:
    "Creamos soluciones en el área del comercio electrónico permitiendo facilitar y optimizar la venta de productos o servicios en línea. ",
    description2: "Te ayudamos a elegir y configurar los servicios de alojamiento web adecuados para tus necesidades.",
    description3: "Implementamos sistemas de autenticación y accesos seguros para proteger tus datos y recursos en línea.",
    description4: "Implementamos carros de compra con sistemas de pagos fáciles y seguros de usar.",
    tech_stack: ["ReactJs", "NodeJs", "MongoDB"],
    github_url: "https://github.com/salesp07/Pokedex-ReactApp",
    demo_url: "https://pokedex-salesp07.netlify.app/",
  },
  {
    img: "/nube.jpg",
    title: "Servicios locales, bases de datos y arquitecturas en la nube",
    description1:
    "Realizamos consultas y mantenimiento periódico de tus bases de datos para garantizar su óptimo funcionamiento.",
    description2: "Monitoreamos y administramos tus servidores para garantizar el alto rendimiento y disponibilidad continua.",
    description3: "Diseñamos sistemas y arquitecturas en la nube que responden a sistemas flexibles con una visión escalable a largo plazo.",
    tech_stack: ["Python", "Flask", "NodeJs", "MongoDB"],
    github_url: "https://github.com/salesp07/JobDetectNode",
    demo_url: "https://jobdetective.onrender.com/",
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bitbucket/bitbucket-original.svg",
    name: "Bitbucket",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original.svg",
    name: "Express",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/fastapi/fastapi-original.svg",
    name: "FastApi",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
    name: "Firebase",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original.svg",
    name: "Docker",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/jetbrains/jetbrains-plain.svg",
    name: "JetBrains",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/oracle/oracle-original.svg",
    name: "Oracle",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/django/django-plain.svg",
    name: "Django",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/anaconda/anaconda-original.svg",
    name: "Anaconda",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/npm/npm-original-wordmark.svg",
    name: "Npm",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
    name: "AWS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original.svg",
    name: "PostgresSQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/microsoftsqlserver/microsoftsqlserver-plain-wordmark.svg",
    name: "SQL Server",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nginx/nginx-original.svg",
    name: "NGINX",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/materialui/materialui-original.svg",
    name: "Material UI",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/trello/trello-plain.svg",
    name: "Trello",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/jira/jira-original.svg",
    name: "Jira",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/slack/slack-original.svg",
    name: "Slack",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/linux/linux-original.svg",
    name: "Linux - Distr's",
  },
];
